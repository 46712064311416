jQuery(document).ready(function($) {

	/*-----------------------------------
	| Plugins
	------------------------------------*/

	// SETUP FAVICON SCRIPT FOR ADDING BADGE IN TAB BAR
	var favicon = new Favico({
	    type : 'rectangle',
    	animation: 'none',
	});

	// SETUP SUMMERNOTE EDITOR
	$('.redactor').summernote({
		height:300,
	});

	// SETUP "CHOSEN" DROPDOWNS
	$('.cSelect').chosen({width: '100%'});
	
	// SETUP DATEPICKER
	$('.datepicker').datepicker();

	// SETUP SELECT2 DROPDOWNS
	$('.fancy-select').select2({
		theme: "bootstrap"
	});

	// $('.datepicker_new').datepicker({
	// 	format: 'dd/mm/yyyy'
	// });

	/*-----------------------------------
	| Bookmarking
	------------------------------------*/

	$('.bookmark-company').click(function(e) {
		e.stopPropagation();
		bookmarkCompany($(this).data("id"), $(this).attr("id"));
	});

	$('.bookmark-job').click(function(e) {
		e.stopPropagation();
		bookmarkJob($(this).data("id"), $(this).attr("id"));
	});

	// TODO - COMBINE THESE FUNCTIONS INTO ONE
	function bookmarkCompany(company_id, element_id) {
		console.log(company_id + ' ' + element_id);

		$.ajax({
			url: '/companies/bookmark',
			type: "post",
			data: {'id':company_id, '_token': $('meta[name="csrf-token"]').attr('content')},
			success: function(data) {
				console.log(data);
				if(data == 'added') {
				 	$('#' + element_id + ' span').addClass('bookmarked');
				}
				if(data == 'removed') {
					$('#' + element_id + ' span').removeClass('bookmarked');	
				}

				updateBookmarks();
			}
		});
	}

	function bookmarkJob(job_id, element_id) {
		console.log(job_id + ' ' + element_id);

		$.ajax({
			url: '/jobs/bookmark',
			type: "post",
			data: {'id':job_id, '_token': $('meta[name="csrf-token"]').attr('content')},
			success: function(data) {
				console.log(data);
				if(data == 'added') {
				 	$('#' + element_id + ' span').addClass('bookmarked');
				}
				if(data == 'removed') {
					$('#' + element_id + ' span').removeClass('bookmarked');	
				}

				updateBookmarks();
			}
		});
	}

	function updateBookmarks() {

		var el = $('#bookmarks ul');

		$.getJSON('/bookmarks', function(data){
			
			console.log(data.job);
			el.html('');

			if(data.company.length > 0) {
				el.append('<li><a href="javascript:void(0);"><strong>Companies</strong></a></li>');
				for (var i = 0; i < data.company.length; i++) {
					el.append('<li><a href="' + data.company[i].link + '">' + data.company[i].title + '</a></li>');
				}
				el.append('<li class="divider"></li>');
			}

			if(data.job.length > 0) {
				el.append('<li><a href="javascript:void(0);"><strong>Jobs</strong></a></li>');
				for (var i = 0; i < data.job.length; i++) {
					el.append('<li><a href="' + data.job[i].link + '">' + data.job[i].title + '</a></li>');
				}
			}
		});
	}

	
	/*-----------------------------------
	| Schedule
	------------------------------------*/

	$('.confirmDelete').click(function(e){
		e.preventDefault();

		if(window.confirm('Are you sure you want to delete this task?')){
			window.location = $(this).attr('href');
		}
	});
	

	/*-----------------------------------
	| Holiday
	------------------------------------*/

	$('#calendar').fullCalendar({
		weekends: false,
		eventSources: [
			{
				url: '/holiday/events',
				type: 'POST',
				data: {
					'_token': $('meta[name=csrf-token]').attr('content')
				}
			}
		],
		eventClick: function(event) {

			console.log(event);
			var today = new Date();

			// console.log(event.start.format() + ' - ' + moment().format('YYYY-MM-DD'));

			if($('body').data('role') == 'admin' && event.start.format() < moment().format('YYYY-MM-DD')){
				window.alert('Sorry, you cannot cancel a holiday that\'s already happened');
			}

			if($('body').data('role') == 'admin' && event.start.format() >= moment().format('YYYY-MM-DD')){

				//var string = 'id=' + event.id;
				var answer = 'Are you sure you want to cancel the holiday?';

				if (confirm(answer)){
			
					$.post('/holiday/delete', {
						'_token': $('meta[name=csrf-token]').attr('content'),
						'id': event.id
					}, function(){
						$('#calendar').fullCalendar('refetchEvents');
					});
				}

			}

		},
		dayClick: function(date, jsEvent, view) {

			window.thisOne = jsEvent.target;
			// console.log(window.thisOne);

			var content;
			var box_title;

			if($('body').data('role') == 'admin'){
				box_title = 'Create Holiday';
				content = '<p><select id="userList">' + window.userlist + '</select></p>' +
					'<p><select id="holidayLength">' + window.admintypelist + '</select></p>' +
					'<a href="#" id="cancelBtn" class="btn btn-danger">Cancel</a> <a href="#" id="confirmBtn" class="btn btn-success">Confirm</a>';
			} else {
				box_title = 'Request Holiday';
				content = '<p><select id="holidayLength">' + window.typelist + '</select></p>' +
					'<a href="#" id="cancelBtn" class="btn btn-danger">Cancel</a> <a href="#" id="confirmBtn" class="btn btn-success">Request</a>';
			}

			$(jsEvent.target).popover({
				title: box_title,
				container: 'body',
				content: content,
				html: true,
				trigger: 'manual'
			});

			$(jsEvent.target).popover('show');

			date = new Date(date);
			window.selectedDate = date.toString("d MMM yyyy");
			window.holidayDate = date.toString('yyyy-MM-dd');
			window.selectedId = $('body').data('userid');

			if($('body').data('role') == 'admin'){
				window.isAdmin = 1;
			} else {
				window.isAdmin = 0;
			}
		
		}
	});

	$('body').on('change','#userList', function() {
		window.selectedId = $(this).val();
	});

	$('body').on('click','#confirmBtn', function(e) {
		e.preventDefault();

		var data = {
			'_token': $('meta[name=csrf-token]').attr('content'),
			date: window.selectedDate,
			holiday_date: window.holidayDate,
			type: $('#holidayLength').val(),
			user_id: window.selectedId,
			admin: window.isAdmin
		};

		console.log(data);

		$.post('/holiday/add', data, function(){
			$('#calendar').fullCalendar('refetchEvents');
		});

		$(window.thisOne).popover('destroy');
	});

	$('body').on('click','#cancelBtn', function(e) {
		console.log('Cancel clicked');
		e.preventDefault();
		$(window.thisOne).popover('destroy');
	});

	/*-----------------------------------
	| Notifications
	------------------------------------*/

	var el = $('#notifications ul'), badge = $('#notifications .badge'), count = 0, cls;

	setInterval(function(){

		$.getJSON('/notifications', function(data){
			
			el.html('');
			count = 0;

			for (var i = 0; i < data.length; i++) {

				cls = '';

				if(data[i].status){
					cls = 'lb';
					count++;
				}

				el.append('<li class="'+cls+'"><a href="'+data[i].action+'" data-id="'+data[i].id+'" class="noti">'+data[i].message+'</a><button data-id="'+data[i].id+'" class="clearBtn">×</button></li>');

			}

			el.append('<li class="divider"></li>');
			el.append('<li><a href="#" id="clearNoti">Clear Notifications</a></li>');

			badge.html(count);
			favicon.badge(count);

			if(count > 0) badge.show();

		});
	}, 1000);

	$('#notifications').on('click','#clearNoti', function(e){
		e.preventDefault();
		$.post('/notifications/clear', {
			'_token': $('meta[name=csrf-token]').attr('content')
		}, function(){
			el.html('');
			el.append('<li class="divider"></li>');
			el.append('<li><a href="#" id="clearNoti">Clear Notifications</a></li>');
		});
	});

	$('#notifications').on('click','.clearBtn', function(e){
		e.preventDefault();
		var id = $(this).data('id');
		var parent = $(this).parent();
		if(window.confirm('Are you sure you wish to delete "'+parent.text().replace('×', '')+'"?')) {
			$.post('/notifications/delete/'+id, {
				'_token': $('meta[name=csrf-token]').attr('content')
			}, function(){
				parent.remove();
				$('#notifications').addClass('open');
			});
		}
	});

	$('#notifications').on('click', '.noti', function(e){
		e.preventDefault();
		var uri = $(this).attr('href');
		$.post('/notifications/read/'+$(this).data('id'), {
			'_token': $('meta[name=csrf-token]').attr('content')
		}, function(){
			if(window.location == uri){
				return window.reload;
			}
			window.location = uri;
		});
	});

	/*-----------------------------------
	| UTILITY
	------------------------------------*/
	$('.youSure').click(function(e){
		e.preventDefault();

		if(window.confirm('Are you sure you want to delete this?')){
			window.location = $(this).attr('href');
		}
	});

});
